<template>
  <div v-if="isServiced" class="container py-2 mb-2 bg-light">
    <div
      v-b-toggle="`service-details-${serviceDetail.id}`"
      class="toggle-button row"
    >
      <div class="col-auto">
        <b-badge variant="primary" class="serviced-badge">
          Serviced
        </b-badge>
      </div>
      <div class="col">
        <div v-if="isDraft">
          Service details are in <strong>draft</strong> status.
        </div>
        <div v-if="isSubmitted">
          Service details are <strong>awaiting review</strong>.
        </div>
        <div v-else-if="isApproved">
          Service details were <strong>approved</strong> by {{ serviceDetail.reviewedBy?.name }} on {{ $formatDate(serviceDetail.reviewed_at) }}.
        </div>
        <div v-else-if="isRejected">
          Service details were <strong>rejected</strong> by {{ serviceDetail.reviewedBy?.name }} on {{ $formatDate(serviceDetail.reviewed_at) }}.
        </div>
      </div>
      <div class="col-auto">
        <font-awesome-icon class="collapsed" :icon="['far', 'plus-square']" />
        <font-awesome-icon class="expanded" :icon="['far', 'minus-square']" />
      </div>
    </div>
    <b-collapse
      :id="`service-details-${serviceDetail.id}`"
      class="mt-3 details"
    >
      <div class="row align-items-center">
        <div class="col-md-4 text-right">
          Comments
        </div>
        <div class="col-md-8">
          <span class="highlight">
            {{ serviceDetail.comments ?? $t('common.forms.none') }}
          </span>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-md-4 text-right">
          Status
        </div>
        <div class="col-md-8">
          <span class="highlight">
            {{ serviceDetail.originalAssetStatus.status }}
          </span>
          <font-awesome-icon :icon="['far', 'arrow-right']" class="mx-2" />
          <span class="highlight">
            {{ recommendedAssetStatus }}
          </span>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-md-4 text-right">
          Condition
        </div>
        <div class="col-md-8">
          <span class="highlight">
            {{ serviceDetail.originalConditionGrade.name }}
          </span>
          <font-awesome-icon :icon="['far', 'arrow-right']" class="mx-2" />
          <span class="highlight">
            {{ recommendedConditionGrade }}
          </span>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-md-4 text-right">
          Warranty Labour
        </div>
        <div class="col-md-8">
          <span class="highlight">
            {{ originalWarrantyLabour }}
          </span>
          <font-awesome-icon :icon="['far', 'arrow-right']" class="mx-2" />
          <span class="highlight">
            {{ recommendedWarrantyLabour }}
          </span>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-md-4 text-right">
          Warranty Parts
        </div>
        <div class="col-md-8">
          <span class="highlight">
            {{ originalWarrantyParts }}
          </span>
          <font-awesome-icon :icon="['far', 'arrow-right']" class="mx-2" />
          <span class="highlight">
            {{ recommendedWarrantyParts }}
          </span>
        </div>
      </div>

      <div class="row align-items-center small mt-2">
        <div class="col-md-4 text-right">
          Submitted by
        </div>
        <div class="col-md-8">
          <div v-if="serviceDetail.submittedBy">
            {{ serviceDetail.submittedBy?.name }} on {{ $formatDate(serviceDetail.submitted_at) }}
          </div>
        </div>
      </div>
      <div class="row align-items-center small">
        <div class="col-md-4 text-right">
          Reviewed by
        </div>
        <div class="col-md-8">
          <div v-if="serviceDetail.reviewedBy">
            {{ serviceDetail.reviewedBy?.name }} on {{ $formatDate(serviceDetail.reviewed_at) }}
          </div>
          <div v-else>
            Awaiting review
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import {BBadge, BCollapse, VBToggle} from "bootstrap-vue";

export default {
  name: 'AssetServiceDetail',
  components: {
    BBadge,
    BCollapse,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    serviceDetail: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      noChange: '<no change>',
    };
  },
  computed: {
    isServiced() {
      return this.serviceDetail?.serviced;
    },
    statusEnum() {
      return this.$enums.ASSET_SERVICE_DETAIL_STATUS[this.serviceDetail.status];
    },
    isDraft() {
      return this.statusEnum === this.$enums.ASSET_SERVICE_DETAIL_STATUS.DRAFT;
    },
    isSubmitted() {
      return this.statusEnum === this.$enums.ASSET_SERVICE_DETAIL_STATUS.SUBMITTED;
    },
    isApproved() {
      return this.statusEnum === this.$enums.ASSET_SERVICE_DETAIL_STATUS.APPROVED;
    },
    isRejected() {
      return this.statusEnum === this.$enums.ASSET_SERVICE_DETAIL_STATUS.REJECTED;
    },
    recommendedAssetStatus() {
      return this.serviceDetail.recommendedAssetStatus?.status || this.noChange;
    },
    recommendedConditionGrade() {
      return this.serviceDetail.recommendedConditionGrade?.name || this.noChange;
    },
    originalWarrantyParts() {
      return this.serviceDetail.warranty_parts_original
        ? this.$formatDate(this.serviceDetail.warranty_parts_original)
        : this.$t('common.forms.none');
    },
    recommendedWarrantyParts() {
      return this.serviceDetail.warranty_parts_recommendation
        ? this.$formatDate(this.serviceDetail.warranty_parts_recommendation)
        : this.noChange;
    },
    originalWarrantyLabour() {
      return this.serviceDetail.warranty_labour_original
        ? this.$formatDate(this.serviceDetail.warranty_labour_original)
        : this.$t('common.forms.none');
    },
    recommendedWarrantyLabour() {
      return this.serviceDetail.warranty_labour_recommendation
        ? this.$formatDate(this.serviceDetail.warranty_labour_recommendation)
        : this.noChange;
    },
  },
}
</script>

<style scoped lang="scss">
  .serviced-badge {
    font-size: .85rem;
    font-weight: normal;
  }
  .toggle-button {
    .collapsed {
      display: none;
    }
    .expanded {
      display: inline-block;
    }
    &.collapsed {
      .collapsed {
        display: inline-block;
      }
      .expanded {
        display: none;
      }
    }
  }
  .details {
    .row {
      margin-top: .25rem;
      .highlight {
        display: inline-block;
        background-color: #ECECEC;
        padding: .1rem .5rem;
        margin: .1rem 0;
        border-radius: .25rem;
        font-size: .85rem;
      }
    }
  }
</style>
