<template>
  <b-list-group-item
    v-bind="$attrs"
    class="asset-list-item d-block small py-lg-1"
    :data-ref="`asset${asset.id}`"
  >
    <div
      class="asset-item py-lg-1"
      @click="$emit('on-asset-click', asset.id)"
    >
      <div v-if="!isMobile && showThumbnail" class="image-container d-flex">
        <img :src="asset.assetModel.thumbnail ? asset.assetModel.thumbnail.url : $emptyImage"/>
      </div>
      <label class="mb-0" style="flex: 1;" :for="`checkbox${asset.id}`">
        <div style="flex: 1;">
          <div class="d-flex" style="display: inline-block">
            <b-form-checkbox
              v-if="canSelect"
              :id="`checkbox${asset.id}`"
              :checked="selectedValues.includes(asset.id)"
              @input="$emit('on-check-item', $event)"
            >
            </b-form-checkbox>
            <AssetDetailsMobile
              v-if="isMobile"
              :asset='asset'
              :include-warranty="includeWarranty"
            />
            <AssetDetails
              v-else
              :asset='asset'
              :include-badges="includeBadges"
              :include-warranty="includeWarranty"
            />
          </div>
        </div>
      </label>
      <div style="margin-left: 1rem">
        <slot></slot>
      </div>
    </div>
    <div v-if="filteredServiceDetails.length">
      <AssetServiceDetail
        v-for="(service, index) in filteredServiceDetails"
        :key="index"
        :service-detail="service"
      />
    </div>
  </b-list-group-item>
</template>

<script>
import {BListGroupItem, BFormCheckbox} from 'bootstrap-vue';
import AssetDetailsMobile from '@/views/assets/AssetDetailsMobile.vue';
import AssetDetails from '@/views/assets/AssetDetails.vue';
import AssetServiceDetail from "@/components/asset/AssetServiceDetail.vue";

export default {
  name: 'SingleAsset',
  inject: ["mq"],
  components: {
    AssetServiceDetail,
    AssetDetails,
    AssetDetailsMobile,
    BListGroupItem,
    BFormCheckbox,
  },
  props: {
    asset: {
      type: Object,
      default: () => {},
    },
    canSelect: {
      type: Boolean,
      default: true,
    },
    selectedValues: {
      type: Array,
      default: () => [],
    },
    showThumbnail: {
      type: Boolean,
      default: true,
    },
    includeBadges: {
      type: Boolean,
      default: true,
    },
    includeWarranty: {
      type: Boolean,
      default: true,
    },
    serviceDetails: {
      type: Array,
      default: () => [],
    }
  },
  computed: {
    isMobile() {
      return ['xs', 'sm', 'md'].includes(this.mq.current);
    },
    onScreenSizeTrigger() {
      if (!this.isMobile) {
        return 'hover';
      }
      return 'focus';
    },
    filteredServiceDetails() {
      const serviceDetails = this.serviceDetails;

      return serviceDetails
        .filter((service) => service.serviced)
        .sort((a, b) => a.submitted_at - b.submitted_at);
    }
  },
};
</script>

<style lang="scss" scoped>
  .image-container {
    width: 100px; margin-right: 1.25rem; align-items: center;
    img {
      width: 100px; height: auto;
    }
  }
  .asset-list-item {
    cursor: pointer;
    &:hover {
      background-color: adjust-lightness($primary, 35%);
    }
  }
  .asset-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > label {
      cursor: pointer;
    }
  }
</style>
